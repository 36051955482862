@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Apfel";
  src: url("./fonts/ApfelGrotezk-Fett.woff2") format("woff2"),
  url("./fonts/ApfelGrotezk-Fett.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Apfel";
  src: url("./fonts/ApfelGrotezk-Regular.woff2") format("woff2"),
  url("./fonts/ApfelGrotezk-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: 'Crunold';
  src: url('./fonts/crunold-oblique-webfont.woff2') format('woff2'),
  url('./fonts/crunold-oblique-webfont.woff') format('woff');
  font-weight: normal;
}


@font-face {
  font-family: 'Crunold';
  src: url('./fonts/crunold-regular-webfont.woff2') format('woff2'),
  url('./fonts/crunold-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Apfel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(0deg, #525CE1 1.85%, #0D0B45 114.28%);
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.speakingIcon {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: dash 5s linear forwards;
}

.custom-shadow-1 {
  box-shadow: 0 4px 27px rgba(0, 0, 0, 0.5);
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes phraseSaid {
  from {
    opacity: 100;
  }
  to {
    opacity: 80;
  }
}

svg {
  max-width: 100%;
  max-height: 400px;
}

svg path {
  pointer-events:all;
}

path.clickable {
  cursor: pointer;
}

path.colorable {
  transition: all ease-in 80ms;
}

.btn-primary {
  @apply bg-vert-1 text-blue-2 hover:bg-[#62fcc4] transition-all
}

.btn-secondary {
  @apply border border-white text-white bg-[#464DC5] transition-all hover:bg-[#4c53c2]
}

.btn-ternary {
  @apply border border-blue-2 text-blue-2 transition hover:bg-[#525CE1]/10
}

.btn-quartary {
  @apply border-b border-white text-white transition md:hover:text-white/80
}

.action-btn-bottom {
  @apply bottom-16 md:bottom-16 xl:md:bottom-16  2xl:bottom-8
}